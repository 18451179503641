import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import vueQuill from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import '@/assets/js/hljs'
import MySearchSelect from '@/components/MySearchSelect'
import LoadingButton from '@/components/ui/LoadingButton'
import Sort from '@/components/ui/Sort'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
Vue.component('LoadingButton', LoadingButton)
Vue.component('Sort',Sort)
// import Icon from 'vue-awesome/components/Icon'
import 'font-awesome/css/font-awesome.css'
// Vue.component('awesome-icon',Icon)
Vue.use(MySearchSelect)

// import iEditor from 'iview-editor'
// import 'iview-editor/dist/iview-editor.css'
// use
// Vue.use(iEditor)
import 'highlight.js/styles/googlecode.css'
import hljs from 'highlight.js'
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)
import websocket from 'vue-native-websocket'


Vue.use(websocket, '', {
  connectManually: true, // 手动连接
  format: 'json', // json格式
  reconnection: true, // 是否自动重连
  reconnectionAttempts: 5, // 自动重连次数
  reconnectionDelay: 2000, // 重连间隔时间
});

hljs.highlightCode = function () {
	//自定义highlightCode方法，将只执行一次的逻辑去掉
	let blocks = document.querySelectorAll('pre code');
	[].forEach.call(blocks, hljs.highlightBlock);
};
router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title == undefined?'默认标题':to.meta.title
    // if (to.meta.requireAuth) {
    //     let token = Cookies.get('access_token');
    //     let anonymous = Cookies.get('user_name');
    //     if (token) {
    //             next({
    //                 path: '/login',
    //                 query: {
    //                     redirect: to.fullPath
    //                 }
    //             })

    //     }
    // }
    next();
})
// 设置浏览器标题

Vue.directive('title', {

  inserted: function (el, binding) {

    document.title = el.dataset.title

  }

})
Vue.use(mavonEditor)
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(vueQuill)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
