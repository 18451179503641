<template>
    <div style="text-align: center;margin-top: 10%;">
      <el-input v-model="msg"/>
        <button @click="send">发消息</button>
        <ul>
          <li v-for="item in msgList">{{item}}</li>
        </ul>
    </div>
</template>

<script>
  import {sendMessageApi} from '@/api/apiTest'
  import { getToken } from '@/utils/token'
export default {
  data() {
    return {
      msgList:["张三","李四"],
      msg:''
    }
  },
  mounted() {
  	this.initWebSocket();
  },
  sockets: {
  	// 连接成功
  	onopen() {
  		console.log("socket success");
  	},
  	// 接收消息
  	onmessage(e) {
  		this.msgList.push(e.data)
  	},
  	// 连接报错
  	onerror() {},
  	// 关闭连接
  	onclose() {
  		console.log("socket close");
  	},
  },
  destroyed() {
  	// 销毁websocket
      this.$disconnect();
  },
  methods: {
    
    initWebSocket(){
      const token = getToken()
      this.$connect("ws://localhost:8080/api/websocket/"+token);
    },
    send(){
      sendMessageApi({message:this.msg})
    }
  },

}
</script>

<style>

</style>
