const ip_key = 'ipAddress'
export const setIp = (ip) => {
  localStorage.setItem(ip_key, ip)
}
export const getIp = () => {
  return localStorage.getItem(ip_key)
}
export const removeIp = () => {
  localStorage.removeItem(ip_key)
}
export const isAuth = () => {
  return !!getIp()
}
