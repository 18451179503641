import request from '@/utils/request'
export const sendMessageApi = (query) => request.get(
'/webSocket/sendMessage'
,{ params: query })
// export const removeMessageApi = (query) => request.get(
// '/webSocaket/removeMessage'
// ,{ params: query })
export function removeMessageById(messageId){
  return request({
    url:'/webSocket/removeMsgById',
    method:'delete',
    params:{messageId},
    useIpAddress:true
  })
}
export function removeMessageApi(query){
  return request({
    url:'/webSocket/removeMessage',
    method:'delete',
    params:query,
    useToken:true
  })
}
export function removeMessageByIdAndUsernameApi(messageId,username){
  return request({
    url:'/webSocket/removeMessageByIdAndUsername',
    method:'delete',
    params:{messageId:messageId,username:username},
    useToken:true
  })
}
