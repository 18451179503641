import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/home/index.vue'
import Layout from '../views/layout/Layout.vue'
import webSocket from '../views/websocket/index.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/myblog',
    redirect: '/home',
  },
  {
    path: '/myblog',
    component: Layout,
    children: [
      {
        path: '/home',
        component: () => import('../views/home/index.vue'),
        meta: {
            title: '首页',
            keepAlive: true, // 需要被缓存
        }
      },
      {
        path:'/quiz',
        component:()=>import("../views/quiz/index.vue"),
        meta:{
          title:'提问',
          keepAlive:true
        }
      },
      {
        path: '/blog',
        component: () => import('../views/article/index.vue'),
        meta: {
            title: '文章',
            keepAlive: true, // 需要被缓存
        }
      },{
        path: '/userSearch',
        component: () => import('../views/usersearch/index.vue'),
        meta: {
            title: '用户',
            keepAlive: true, // 需要被缓存
        }
      },
      // {
      //   path: '/webSocket',
      //   component: () => import('../views/websocket/index.vue'),
      //   meta: {
      //       title: 'webSocket测试',
      //       keepAlive: true, // 需要被缓存
      //   }
      // },
      {
        path: '/user',
        component: () => import('../views/user/index.vue'),
        children:[
          {
            path: '/',
            redirect:'/userBlog'
          },{
            path: '/publish',
            component: () => import('../views/publish/index.vue'),
            meta: {
                title: '创作',
                keepAlive: true, // 需要被缓存
            }
          },{
            path: '/userBlog',
            component: () => import('../views/user/UserBlog.vue'),
            meta: {
                title: '已经发表的文章',
                keepAlive: true, // 需要被缓存
            }
          },{
            path:'/drafts',
            component: ()=> import("../views/user/Drafts.vue"),
            meta: {
                title: '草稿箱',
                keepAlive: true, // 需要被缓存
            }
          },{
            path:'/material',
            component: ()=> import("../views/user/Material.vue"),
            meta: {
                title: '资料',
                keepAlive: true, // 需要被缓存
            }
          },{
            path:'/message',
            component: ()=> import("../views/user/Message.vue"),
            meta: {
                title: '消息',
                keepAlive: true, // 需要被缓存
            }
          },{
            path:'/myAttention',
            component: ()=>import('../views/user/MyAttention.vue'),
            meta:{
              title:'我的关注',
              keepAlive:true
            }
          },{
            path:'/userComment',
            component: ()=>import('../views/user/UserComment.vue'),
            meta:{
              title:'我的评论',
              keepAlive:true
            },
          }
        ]
      },{
          path: '/details',
          component: () => import('../views/details/index.vue'),
          meta: {
              title: '详情',
              keepAlive: true, // 需要被缓存
          }
      },
      {
        path: '/login',
        component: () => import('../views/login/index.vue'),
        meta: {
            title: '登录',
            keepAlive: true, // 需要被缓存
        }
      },
    ]
  },


]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  next()
})

export default router
