const state={
  commentMessageTotal:0,
  praiseStepMessageTotal:0,
  quizMessageTotal:0,
  messageTotal:0,
  attentionMeMessageTotal:0,
  noticeMessageTotal:0,
  noticeMessageList:[],
  fileUploadProgress:0
}
const mutations={
  COMMENT_TOTAL_ADD:(state)=>{
    state.commentMessageTotal++
    state.messageTotal++
  },
  PRAISE_STEP_TOTAL_ADD:(state)=>{
    state.praiseStepMessageTotal++
    state.messageTotal++
  },
  QUIZ_TOTAL_ADD:(state)=>{
    state.quizMessageTotal++
    state.messageTotal++
  },
  COMMENT_TOTAL_CLEAR:(state)=>{
    state.messageTotal-=state.commentMessageTotal
    state.commentMessageTotal=0
  },
  PRAISE_STEP_TOTAL_CLEAR:(state)=>{
    state.messageTotal-=state.praiseStepMessageTotal
    state.praiseStepMessageTotal=0
  },
  QUIZ_TOTAL_CLEAR:(state)=>{
    state.messageTotal-=state.quizMessageTotal
    state.quizMessageTotal=0
  },
  ATTENTIONME_TOTAL_ADD:(state)=>{
    state.attentionMeMessageTotal++
    state.messageTotal++
  },
  ATTENTIONME_TOTAL_CLEAR:(state)=>{
    state.messageTotal-=state.attentionMeMessageTotal
    state.attentionMeMessageTotal=0
  },
  NOTICE_TOTAL_CLEAR:(state,messageId)=>{
    state.messageTotal-=1
    state.noticeMessageTotal-=1
    let len=state.noticeMessageList.length
    for(let i=0;i<len;++i){
      if(state.noticeMessageList[i].id==messageId){
        state.noticeMessageList.splice(i, 1)
        break;
      }
    }
  },
  NOTICE_MESSAGE_ADD:(state,noticeMessage)=>{
    state.noticeMessageTotal++
    state.messageTotal++
    state.noticeMessageList.push(noticeMessage)
  },
  FILE_UPLOAD_PROGRESS_UP:(state,number)=>{
    state.fileUploadProgress=number
  },
  FILE_UPLOAD_PROGRESS_REST:(state)=>{
    state.fileUploadProgress=0
  }
}
const actions={
  fileUPloadProgressRest({commit,state}){
    commit('FILE_UPLOAD_PROGRESS_REST')
  },
  fileUploadProgressUp({commit,state},number){
    commit('FILE_UPLOAD_PROGRESS_UP',number)
  },
  commentTotalAdd({commit,state}){
    commit('COMMENT_TOTAL_ADD')
  },
  commentTotalClear({commit,state}){
    commit('COMMENT_TOTAL_CLEAR')
  },
  praiseStepTotalAdd({commit,state}){
    commit('PRAISE_STEP_TOTAL_ADD')
  },
  praiseStepTotalClear({commit,state}){
    commit('PRAISE_STEP_TOTAL_CLEAR')
  },
  quizTotalAdd({commit,state}){
    commit('QUIZ_TOTAL_ADD')
  },
  quizTotalClear({commit,state}){
    commit('QUIZ_TOTAL_CLEAR')
  },
  attentionMeAdd({commit,state}){
    commit('ATTENTIONME_TOTAL_ADD')
  },
  attentionMeClear({commit,state}){
    commit('ATTENTIONME_TOTAL_CLEAR')
  },
  noticeMessageAdd({commit,state},noticeMessage){
    commit('NOTICE_MESSAGE_ADD',noticeMessage)
  },
  noticeClear({commit,state},messageId){
    commit('NOTICE_TOTAL_CLEAR',messageId)
  }
}
export default{
  namespaced: true,
  state,
  mutations,
  actions
}
