import { setIp, getIp } from '../../utils/ipAddress'
const state={
  ipAddress:getIp()||''
}
const mutations={
  CACHE_IP_ADDRESS:(state,ip)=>{
    state.ipAddress=ip
    setIp(ip)
  }
}
const actions={
  cacheIpAddress({commit,state}){
    let ip=getIp()
    if(!ip || ip==''){

      fetch('https://api.ipify.org?format=json')
      .then(res=>res.json())
      .then(data=>{
        commit('CACHE_IP_ADDRESS',data.ip)
      })
      .catch(error=>{
        console.error(error)
      })

    }else{
      commit('CACHE_IP_ADDRESS',ip)
    }

  }
}
export default{
  namespaced: true,
  state,
  mutations,
  actions
}
