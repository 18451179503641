const getters={
  commentMessageTotal:state=>state.message.commentMessageTotal,
  praiseStepMessageTotal:state=>state.message.praiseStepMessageTotal,
  quizMessageTotal:state=>state.message.quizMessageTotal,
  messageTotal:state=>state.message.messageTotal,
  username:state=>state.user.user.username,
  nickname:state=>state.user.user.nickname,
  attentionMeMessageTotal:state=>state.message.attentionMeMessageTotal,
  noticeMessageTotal:state=>state.message.noticeMessageTotal,
  noticeMessageList:state=>state.message.noticeMessageList,
  fileUploadProgress:state=>state.message.fileUploadProgress,
  ipAddress:state=>state.ipAddress.ipAddress
}

export default getters
