var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout"},[_c('div',{staticClass:"box header",style:(_vm.$route.path!='/home'?'':'')},[_c('el-row',{staticStyle:{"width":"100%","border":"none"}},[_c('el-col',{attrs:{"md":3,"xs":0}},[_c('div',{staticClass:"img-box"},[_c('h1',{staticStyle:{"color":"#FAF8F9","margin":"0px","padding":"5px"}},[_vm._v("Q Y F")])])]),_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"md":{span:12,offset:9},"xs":{span:24}}},[_c('el-row',[_c('el-col',{attrs:{"md":{span:5},"xs":0}},[_c('router-link',{class:_vm.$route.path === '/home'
            ? 'layout_menu_router_link_a_selected'
            : 'layout_menu_router_link_a',attrs:{"to":"/home","tag":"a"}},[_vm._v(" 首页 ")])],1),_c('el-col',{attrs:{"md":{span:5},"xs":6}},[_c('router-link',{class:_vm.$route.path === '/blog'
            ? 'layout_menu_router_link_a_selected'
            : 'layout_menu_router_link_a',attrs:{"to":"/blog","tag":"a"}},[_vm._v(" 文章 ")])],1),_c('el-col',{attrs:{"md":{span:5},"xs":6}},[_c('router-link',{class:_vm.$route.path === '/quiz'
            ? 'layout_menu_router_link_a_selected'
            : 'layout_menu_router_link_a',staticStyle:{"margin-right":"20px"},attrs:{"to":"/quiz","tag":"a"}},[_vm._v(" 提问 ")])],1),_c('el-col',{attrs:{"md":{span:5},"xs":6}},[_c('router-link',{class:_vm.$route.path === '/userSearch'
            ? 'layout_menu_router_link_a_selected'
            : 'layout_menu_router_link_a',staticStyle:{"margin-right":"20px"},attrs:{"to":"/userSearch","tag":"a"}},[_vm._v(" 友链 ")])],1),_c('el-col',{attrs:{"md":{span:4},"xs":6}},[(_vm.$store.state.user.user.username === undefined)?_c('router-link',{class:_vm.$route.path === '/login'
            ? 'layout_menu_router_link_a_selected'
            : 'layout_menu_router_link_a',staticStyle:{"margin-right":"20px"},attrs:{"to":"/login","tag":"a"}},[_vm._v(" 登录 ")]):_vm._e(),(_vm.$store.state.user.user.username != undefined)?_c('el-popover',{staticStyle:{"margin-right":"100px","margin-left":"10px"},attrs:{"placement":"top-start","width":"200","trigger":"hover"}},[_c('el-avatar',{staticStyle:{"margin-top":"5px"},attrs:{"slot":"reference","size":"large","src":_vm.$store.state.user.user.avatar},slot:"reference"}),_c('div',[_c('h3',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.$store.state.user.user.nickname))]),(_vm.$store.state.user.user != null)?_c('router-link',{key:"publishCreate",staticClass:"publish_link menu_user",staticStyle:{"color":"#ffffff"},attrs:{"to":{
                 path: '/publish',
                 query: { 
                  username: _vm.$store.state.user.user.username,
                 },
               }}},[_vm._v(" 创作 ")]):_vm._e(),(_vm.$store.state.user.user != null)?_c('router-link',{staticClass:"menu_user",attrs:{"to":{
                 path: '/message',
                 query: { username: _vm.$store.state.user.user.username,
                 },
               }}},[_vm._v(" 消息 "),(_vm.messageTotal>0)?_c('el-badge',{staticClass:"mark",staticStyle:{"margin-right":"-20px"},attrs:{"value":_vm.messageTotal,"max":99}}):_vm._e()],1):_vm._e(),(_vm.$store.state.user.user != null)?_c('router-link',{staticClass:"menu_user",attrs:{"to":{
                 path: '/drafts',
                 query: { username: _vm.$store.state.user.user.username,
                 },
               }}},[_vm._v(" 草稿箱 ")]):_vm._e(),(_vm.$store.state.user.user != null)?_c('router-link',{staticClass:"menu_user",attrs:{"to":{
                 path: '/material',
                 query: { username: _vm.$store.state.user.user.username,
                 },
               }}},[_vm._v(" 资料 ")]):_vm._e(),(_vm.$store.state.user.user != null)?_c('router-link',{staticClass:"menu_user",attrs:{"to":{
                 path: '/userBlog',
                 query: { username: _vm.$store.state.user.user.username,
                 },
               }}},[_vm._v(" 我的文章 ")]):_vm._e(),(_vm.$store.state.user.user != null)?_c('router-link',{staticClass:"menu_user",attrs:{"to":{
                 path: '/myAttention',
                 query: { username: _vm.$store.state.user.user.username,
                 },
               }}},[_vm._v(" 我的关注 ")]):_vm._e(),(_vm.$store.state.user.user != null)?_c('router-link',{staticClass:"menu_user",attrs:{"to":{
                 path: '/userComment',
                 query: { username: _vm.$store.state.user.user.username,
                 },
               }}},[_vm._v(" 我的评论 ")]):_vm._e(),_c('el-button',{on:{"click":_vm.logout}},[_vm._v(" 退出登录 ")])],1)],1):_vm._e(),(_vm.messageTotal>0)?_c('el-badge',{staticClass:"item",staticStyle:{"margin-right":"77px","margin-top":"-85px"},attrs:{"is-dot":""}}):_vm._e()],1)],1)],1),_c('el-col',{attrs:{"md":24}},[_c('span',{class:_vm.allNoticeVisible?'al-notice-show':'al-notice-hidden'},[_vm._v(" "+_vm._s(_vm.message?_vm.message.message:'')+" "),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.receiveNotice}},[_vm._v("收到")])])])],1)],1),_c('div'),_c('a',{staticClass:"beianmiit",attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/recordQuery","target":"_blank"}},[_vm._v("冀ICP备2021011772号")]),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }