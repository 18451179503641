<template>
  <div class="layout">
    <div class="box header" :style="$route.path!='/home'?'':''">
      <el-row style="width: 100%;border: none;">
        <el-col :md="3" :xs="0">
          <div class="img-box">
            <!-- <el-image
              style="width: 80px; height: 80px"
              fit="contain"
              src="http://qianyafan.top:8080/images/blog-logo.png"
            >
            </el-image> -->
            <h1 style="color:#FAF8F9;margin: 0px;padding: 5px;">Q Y F</h1>
          </div>
          </el-col>
        <el-col :md="{span:12,offset:9}" :xs="{span:24}"style="text-align: right;">
      <!-- <div style="float: right;"> -->
        <el-row>
          <el-col :md="{span:5}" :xs="0">
        <router-link
          to="/home"
          tag="a"
          :class="
            $route.path === '/home'
              ? 'layout_menu_router_link_a_selected'
              : 'layout_menu_router_link_a'
          "
        >
          首页
        </router-link>
        </el-col>
        <el-col :md="{span:5}":xs="6">
        <router-link
          to="/blog"
          tag="a"
          :class="
            $route.path === '/blog'
              ? 'layout_menu_router_link_a_selected'
              : 'layout_menu_router_link_a'
          "

        >
          文章
        </router-link>
        </el-col>
        <el-col :md="{span:5}":xs="6">
        <router-link
          to="/quiz"
          tag="a"
          :class="
            $route.path === '/quiz'
              ? 'layout_menu_router_link_a_selected'
              : 'layout_menu_router_link_a'
          "
          style="margin-right: 20px;"
        >
          提问
        </router-link>
        </el-col>
        <el-col :md="{span:5}":xs="6">
        <router-link
          to="/userSearch"
          tag="a"
          :class="
            $route.path === '/userSearch'
              ? 'layout_menu_router_link_a_selected'
              : 'layout_menu_router_link_a'
          "
          style="margin-right: 20px;"
        >
          友链
        </router-link>
        </el-col>
        <el-col :md="{span:4}":xs="6">
        <router-link
          to="/login"
          tag="a"
          :class="
            $route.path === '/login'
              ? 'layout_menu_router_link_a_selected'
              : 'layout_menu_router_link_a'
          "
          v-if="$store.state.user.user.username === undefined"
          style="margin-right: 20px"
        >
          登录
        </router-link>
        <el-popover
        v-if="$store.state.user.user.username != undefined"
            style="margin-right: 100px;margin-left: 10px;"
           placement="top-start"
           width="200"
           trigger="hover">
             <el-avatar
             slot="reference"
               size="large"
               style="margin-top: 5px;"
               :src="$store.state.user.user.avatar"
             >
             </el-avatar>
             <div>
               <h3 style="text-align: center;">{{
                 $store.state.user.user.nickname
               }}</h3>
               <router-link
               class="publish_link menu_user "
               key="publishCreate"
                 :to="{
                   path: '/publish',
                   query: { 
                    username: $store.state.user.user.username,
                   },
                 }"
                 v-if="$store.state.user.user != null"
                style="color: #ffffff;"
               >
                     创作
               </router-link>
               <router-link
               class="menu_user"
                 :to="{
                   path: '/message',
                   query: { username: $store.state.user.user.username,
                   },
                 }"
                 v-if="$store.state.user.user != null"
               >
                       消息
                   <el-badge
                    style="margin-right: -20px;"
                    v-if="messageTotal>0"
                    :value="messageTotal" :max="99" class="mark"/>
               </router-link>

               <router-link
               class="menu_user"
                 :to="{
                   path: '/drafts',
                   query: { username: $store.state.user.user.username,
                   },
                 }"
                 v-if="$store.state.user.user != null"
               >
                       草稿箱
               </router-link>
               <router-link
               class="menu_user"
                 :to="{
                   path: '/material',
                   query: { username: $store.state.user.user.username,
                   },
                 }"
                 v-if="$store.state.user.user != null"
               >
                       资料
               </router-link>
               <router-link
               class="menu_user"
                 :to="{
                   path: '/userBlog',
                   query: { username: $store.state.user.user.username,
                   },
                 }"
                 v-if="$store.state.user.user != null"
               >
                  我的文章
               </router-link>
               <router-link
               class="menu_user"
                 :to="{
                   path: '/myAttention',
                   query: { username: $store.state.user.user.username,
                   },
                 }"
                 v-if="$store.state.user.user != null"
               >
                  我的关注
               </router-link>
               <router-link
               class="menu_user"
                 :to="{
                   path: '/userComment',
                   query: { username: $store.state.user.user.username,
                   },
                 }"
                 v-if="$store.state.user.user != null"
               >
                  我的评论
               </router-link>
              <el-button @click="logout">
                退出登录
              </el-button>
             </div>
         </el-popover>
         <el-badge
          style="margin-right: 77px;margin-top: -85px;"
          v-if="messageTotal>0"
           class="item"
           is-dot
           />
           </el-col>
           </el-row>
      <!-- </div> -->
      </el-col>
      <el-col :md="24">
        <span
        :class="allNoticeVisible?'al-notice-show':'al-notice-hidden'"
        >
        {{message?message.message:''}}
        <span style="cursor: pointer;
        "
        @click="receiveNotice"
        >收到</span>
        </span>
      </el-col>
      </el-row>
      
    </div>
    <div></div>
    <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank" 
    class="beianmiit">冀ICP备2021011772号</a>
    <!-- <Bg></Bg> -->
    <router-view/>
    
  </div>
</template>
<script>
import Bg from '../../components/background'
import global from '@/api/global_variable.js'
import { removeToken,getToken } from '@/utils/token'
// import { logoutApi } from '@/api/user'
import {getUserIP} from '@/utils/user_ip'
import { mapGetters } from 'vuex'
import {removeMessageById} from '@/api/message'
export default {
  data () {
    return {
      avatarBaseUrl: global.baseURL,
      imgURL:process.env.VUE_APP_BASE_IMAGES_URL,
      activeIndex: this.$route.path,
      allNoticeVisible:false,
      message:undefined
    }
  },
  created () {
    this.initWebSocket()
  },
  mounted() {
  	// this.initWebSocket()
  },
  computed: {
    ...mapGetters([
      'commentMessageTotal',
      'messageTotal',
      'ipAddress'
    ])
  },
  sockets: {
  	// 连接成功
  	onopen() {
  	},
  	// 接收消息
  	onmessage(e) {
      let message=JSON.parse(e.data)
      switch(message.type){
        case "comment":
          this.$store.dispatch('message/commentTotalAdd')
          break
        case "praiseStep":
          this.$store.dispatch('message/praiseStepTotalAdd')
          break
        case "quiz":
          this.$store.dispatch('message/quizTotalAdd')
          break
        case "notice":
          this.$store.dispatch('message/noticeMessageAdd',message)
          break
        case "attention":
          this.$store.dispatch('message/attentionMeAdd')
          break
        case "fileUploadProgress":
          this.$store.dispatch('message/fileUploadProgressUp',message.message)
          break;
        case "friendshipLink":
          this.$store.dispatch('message/noticeMessageAdd',message)
          break
        case "strangeNotice":
          this.allNoticeVisible=true
          this.message=message
      }
  	},
  	// 连接报错
  	onerror() {},
  	// 关闭连接
  	onclose() {
  	},
  },
  destroyed() {
  	// 销毁websocket
      this.$disconnect();
  },
  // components: { Bg },
  methods: {
    receiveNotice(){
      this.allNoticeVisible=false
      removeMessageById(this.message.id).then((res)=>{
        if(res.data.success){
          this.$message({
            type:'success',
            message:'您收到了通知，此通知将不会再次推送给您！'
          })
        }else{
          this.$message({
            type:'warning',
            message:res.data.errorMsg
          })
        }
      })
    },
    async getIpAddress(){
        await fetch('https://api.ipify.org?format=json')
        .then(res=>res.json())
        .then(data=>{
          this.ipAddress=data.ip
        })
        .catch(error=>{
          console.error(error)
        })
    },
    async initWebSocket(){
      var username=this.$store.state.user.user.username
      let host="qianyafan.top:8080"
      // let host="localhost:8080"
      // this.$connect("ws://qianyafan.top:8080/api/websocket/"+username)
      this.$store.dispatch('ipAddress/cacheIpAddress')
      let connectPath
      if(username){
        connectPath="wss://"+host+"/api/websocket/"+username
      }else if(this.ipAddress){
        connectPath="wss://"+host+"/api/websocket/"+this.ipAddress
      }
      this.$connect(connectPath)
      console.log(connectPath)
    },
    handleSelect (key, keyPath) {

    },
    logout () {
      // logoutApi()
      removeToken()
      // 清除状态保持
      window.localStorage.clear()
      // 状态保持清除后刷新页面
      window.location.reload()
    }
  }
}
</script>
<style lang="less">
  .beianmiit{
    width: 100%;
    position: absolute;
    color: #ffffff;
    bottom: 0px;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    color: #808080;
    z-index: 99;
  }
  .beianmiit:hover{
    color: #1059B7;
  }
  .al-notice-show{
    // position: absolute;
    display: inline-block;
    text-align: center;
    // margin-left: 1550px;
    font-weight: bold;
    font-size: 20px;
    transform: translate(1550px);
    animation: showNotice 10s linear infinite;
    // animation: colorChange 2s linear infinite;
  }
  .al-notice-show:hover{
    animation-play-state:paused
  }
  @keyframes showNotice{
    0%{
      transform: translate(1550px);
    }
    10%{
      color: #E44034;
    }
    20%{
      color: #259644;
    }
    30%{
      color: #307DEF;
    }
    40%{
      color: #E44034;
    }
    50%{
      color: #259644;
    }
    60%{
      color: #307DEF;
    }
    70%{
      color: #E44034;
    }
    80%{
      color: #259644;
    }
    90%{
      color: #307DEF;
    }
    100%{
      transform: translate(-500px);
    }
  }
  .al-notice-hidden{
    display: none;
  }
  .header{
    // height: 0px;
    // text-overflow: ;
    overflow: hidden;
    animation: showLayout 1.5s;
  }
  @keyframes showLayout{
    0%{
      height: 0px;
    }
    100%{
      height: 50px;
    }
  }
.publish_link{
  background-color: #D46A8E;
  color: #ffffff;
}
.publish_link:hover{
  background-color: #EC8899;
}
.menu_user{
  transition-property: all;
  transition-duration: 0.5s;
  display: inline-block;
  text-decoration: none;
  border-radius: 10px;
  padding: 0px 10px 0px 10px;
  height: 30px;
  text-align: center;
  color: #5D5F65;
  line-height: 30px;
  margin:0px 0px 10px 10px;
}
.menu_user:hover{
  color: #DBDB8E;
}
.message_item {
  // margin-top: 10px;
  // margin-right: 40px;
  width: 100%;
}
.user_home {
  text-decoration: none;
  text-align: center;
  color: #606266;
}
.user_home:hover {
}
.layout {
  z-index: 10;
  height: 100%;
  // position: relative;
}
.layout_menu_router_link_a {
  text-decoration: none;
  font-size: 18px;
  display: inline-block;
  height: 100%;
  line-height: 50px;
  letter-spacing: .5em;
  width: 100px;
  transition-property: all;
  transition-duration: 0.5s;
  text-align: center;
  float: left;
  color: #f3f4f6;
}
.layout_menu_router_link_a_user {
  text-decoration: none;
  font-size: 14px;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  width: 200px;
  transition-property: all;
  transition-duration: 0.5s;
  text-align: center;
  float: left;
  color: #f3f4f6;
}
.layout_menu_router_link_a_selected {
  letter-spacing: .5em;
  text-decoration: none;
  color: #DBDB8E;
  font-size: 18px;
  display: inline-block;
  height: 100%;
  line-height: 50px;
  width: 100px;
  transition-property: all;
  transition-duration: 0.5s;
  float: left;
  text-align: center;
}
.layout_menu_router_link_a:hover {
  // background-color: #dcdfe6;
  color: #DBDB8E;
}
.box {
  z-index: 999;
  position: absolute;
  top: 0;
  // z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .img-box {
    display: flex;
    align-items: center;
    // width: 70px;
    // height: 70px;
    // padding: 20px;
    // margin-top: 20px;
  }
  .img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.el-menu-demo.el-menu--horizontal.el-menu {
  border-bottom: none;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  .el-menu-item.is-active {
    border-bottom: none;
  }
  .el-menu-item {
    display: flex;
    font-size: 16px;
  }
}
.photo-box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}
.photo {
  width: 100%;
  height: 100%;
}
.layout_username {
  margin-left: 5px;
  overflow: hidden;
  float: left;
  line-height: 40px;
  color: #000000;
}
</style>
