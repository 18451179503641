import axios from 'axios'
import qs from 'qs'
import router from '@/router'
import store from '@/store'
import { getToken } from '@/utils/token'
import { Message } from 'element-ui'
import { getIp } from '@/utils/ipAddress'
// import qs from 'qs'
const request = axios.create({
  baseURL: 'https://qianyafan.top:8080',
  // baseURL:'https://localhost:8080',
  // baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
})
// 添加请求拦截器
request.interceptors.request.use(async function (config) {
  const token = getToken()
  // 在发送请求之前做些什么
  if(token!='' && config.useToken){
    config.headers.token = token
  }
  let ip=getIp()
  if(config.useIpAddress && ip!=''){
    config.headers.ipAddress=ip
  }
  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  if (response.data && response.data.code === '414') {
    router.push('/login')
    Message.error('登录过期，请重新登录')
    store.commit('user/updateToken', '')
    store.commit('user/updateUser', {})
  }
  if(response.data && response.data.code=='413'){
    Message.error(response.data.errorMsg)
  }
  if(response.data.code=='500'){
    Message.error(response.data.errorMsg)
  }
  return response
}, function (error) {
  return Promise.reject(error)
})
export default request
