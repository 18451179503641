import { setToken, getToken } from '../../utils/token'
export default {
  namespaced: true,
  state: () => ({
    token: getToken() || '',
    user: JSON.parse(localStorage.getItem('user') || '{}')
  }),
  mutations: {
    updateToken (state, token) {
      state.token = token
      setToken(token)
    },
    updateUser (state, user) {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user))
    }
  }
}
