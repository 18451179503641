<template>
    <i class="el-icon-sort-up sort"
    :style="{
      transform:sort=='asc'?'rotate(0deg)':'rotate(180deg)'
    }"
    @click.stop="change"
    ></i>
</template>

<script>
  export default{
    name:'Sort',
    data() {
      return {
        sort: 'asc'
      }
    },
    methods: {
      change() {
        if(this.sort=='asc'){
          this.sort='desc'
        }else{
          this.sort='asc'
        }
        this.$emit('change',this.sort)
      }
    },
  }
</script>

<style scoped>
.sort{

  display: inline-block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  transition: all 0.5s;
}
.sort:hover{
  color: #2F7CEE;
}
</style>
