
export default {
  namespaced: true,
  state: () => ({

  }),
  mutations: {

  }
}
