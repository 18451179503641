<template>
  <div>
    <!-- <el-select collapse-tags v-model="form.codeStyle" placeholder="请选择代码块配色" style="width:100%;">
      <div class="el-input" style="width:100%;">
        <input v-model="codeStyleValue" placeholder="请输入" class="el-input__inner"
          style="border: none;outline: none;" @keyup="codeStyleSearch" />
      </div>
      <el-option style="display: none;" key="none" value="none" label="暂无数据">
      </el-option>

      <el-option v-for="(codeStyleItem,index) in codeStyleList" :key="codeStyleItem.style"
        :label="codeStyleItem.style" :value="codeStyleItem.style"></el-option>
    </el-select> -->
  </div>
</template>

<script>
  export default{
    name:'MySearchSelect',
    data(){
      return{

      }
    },
    methods: {
    },
  }
</script>

<style>
</style>
